








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import Comment from '@improve/common-utils/src/model/Comment';
import Ticket from '@improve/common-utils/src/model/Ticket';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseUserAvatar from '../widgets/BaseUserAvatar.vue';
import BaseButton from '../widgets/BaseButton.vue';
import BaseComment from '../widgets/BaseComment.vue';

@Component({
  name: 'BaseTicketComments',
  components: {
    BaseUserAvatar,
    BaseButton,
    BaseComment,
    BaseTextArea
  }
})
export default class BaseTicketComments extends Vue {
  @Prop() comments!: Array<Comment>;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Prop({ default: false }) readonly isPostingComment!: boolean;

  @Getter currentUser?: User;

  @Getter userById!: (uId: string) => User | null;

  @Getter allTeamsById!: (tId: string) => Team | null;

  newCommentText = '';

  get newestComments(): Array<Comment> {
    return this.comments.sort((a: any, b: any) => b.createdAt - a.createdAt);
  }

  authorIsCreator(comment: Comment): boolean {
    return comment.author === this.ticket.creator;
  }

  getCommentAvatar(comment: Comment): string | null {
    const user = this.userById(comment.author);
    return user && user.avatar;
  }

  getAuthorName(comment: Comment): string {
    const user = this.userById(comment.author);
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return '';
  }

  getUnitName(comment: Comment): string {
    const user = this.userById(comment.author);
    const team = user && user.unit ? this.allTeamsById(user.unit) : null;
    return team ? team.name : '';
  }

  async postComment(): Promise<void> {
    if (!(this.validateCommentEditor())) {
      return;
    }
    this.$emit('postComment', this.newCommentText);
    this.newCommentText = '';
    this.resetCommentEditor();
  }

  validateCommentEditor(): boolean {
    if (!this.newCommentText || !this.newCommentText.length) {
      const comp: any = this.$refs.obs;
      comp.setErrors({
        commentField: [this.$t('error.commentIsRequired')]
      });
      return false;
    }
    return true;
  }

  resetCommentEditor(): void {
    const comp: any = this.$refs.obs;
    comp.reset();
  }
}
