






























import Vue from 'vue';
import { Getter } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';

import Comment from '@improve/common-utils/src/model/Comment';
import User from '@improve/common-utils/src/model/User';
import BaseUserAvatar from './BaseUserAvatar.vue';
import BasePill from './BasePill.vue';

@Component({
  name: 'BaseComment',
  components: {
    BaseUserAvatar,
    BasePill
  }
})
export default class BaseComment extends Vue {
  @Getter currentUser?: User;

  @Prop({ default: false }) readonly isCreator!: boolean;

  @Prop({ default: null }) readonly userAvatarImg!: string;

  @Prop({ default: null }) readonly username!: string;

  @Prop({ default: null }) readonly unit!: string;

  @Prop({ default: null }) readonly comment!: Comment;

  mounted(): void {
    if (this.$route.hash) {
      const element = document.querySelector(this.$route.hash) as HTMLElement;
      if (element) {
        setTimeout(() => window.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth'
        }), 100);
      }
    }
  }

  get isCommentHighlighted(): boolean {
    return this.$route.hash === `#comment_${this.comment.id}`;
  }

  get userAndUnit(): string {
    if (!this.username || !this.unit) return '';
    return `${this.username}, ${this.unit}`;
  }

  get commentDate(): string {
    return this.$date(this.comment.createdAt).format('DD.MM.YYYY HH:mm');
  }
}
