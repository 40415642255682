











































































































































































































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';

import BaseTasks from '@improve/common-components/src/components/ticket/BaseTasks.vue';
import BaseSkeleton from '@improve/common-components/src/components/widgets/BaseSkeleton.vue';
import BaseExternalFields
  from '@improve/common-components/src/components/widgets/BaseExternalFields.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseUserThumbnail
  from '@improve/common-components/src/components/widgets/BaseUserThumbnail.vue';
import BaseDelegateThumbnail
  from '@improve/common-components/src/components/widgets/BaseDelegateThumbnail.vue';
import BaseTeamCard from '@improve/common-components/src/components/widgets/BaseTeamCard.vue';
import TicketStatusSwitcher
  from '@improve/common-components/src/components/ticket/TicketStatusSwitcher.vue';
import TimeLineStatuses
  from '@improve/common-components/src/components/ticket/TimeLineStatuses.vue';
import TicketDetailReactions
  from '@improve/common-components/src/components/ticket/TicketDetailReactions.vue';
import { FileModel } from '@improve/common-utils/src/types/FileModel';
import BaseUserAssign from '@improve/common-components/src/components/user/BaseUserAssign.vue';
import GenericFileIcon
  from '@improve/common-components/src/components/widgets/media/GenericFileIcon.vue';
import videoIcon from '@improve/common-components/src/assets/video-icon.svg';
import Ticket from '@improve/common-utils/src/model/Ticket';
import User from '@improve/common-utils/src/model/User';
import Topic from '@improve/common-utils/src/model/Topic';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import Team from '@improve/common-utils/src/model/Team';
import StatsData from '@improve/common-utils/src/model/StatsData';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import TaskUpdateParams from '@improve/common-utils/src/types/TaskUpdateParams';
import { FieldGroup } from '@improve/common-utils/src/model/DynamicField';
import WontDoReasonInput from '../components/widgets/WontDoReasonInput.vue';

@Component({
  name: 'TicketDetails',
  components: {
    BaseSkeleton,
    BaseButton,
    BaseTasks,
    BaseUserThumbnail,
    BaseDelegateThumbnail,
    BaseTeamCard,
    BaseUserAssign,
    BaseExternalFields,
    TicketStatusSwitcher,
    TimeLineStatuses,
    TicketDetailReactions,
    WontDoReasonInput,
    GenericFileIcon
  }
})
export default class TicketDetails extends Vue {
  @Getter currentUser?: User;

  @Getter activeWorkflowStatuses!: Array<string>;

  @Getter allTeamsById!: (tId: string) => Team;

  @Getter teamStatsByID!: Map<string, StatsData>;

  @Getter ticketsByCanonicalId!: Map<string, Ticket>;

  @Getter organizationTopicsById!: (tId: string) => Topic | null;

  @Getter externalFields!: Array<FieldGroup>;

  @Action fetchWorkflow!: ActionMethod;

  @Action getTicketById!: ActionMethod;

  @Action fetchTeamStats!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  @Action updateTasks!: ActionMethod;

  @Action createTicketComment!: ActionMethod;

  @Action updateTicketStatus!: ActionMethod;

  @Action setAlertMessage!: ActionMethod;

  @Action fetchExternalFields!: ActionMethod;

  files: any = [];

  icons = { video: videoIcon };

  private showUsersSearch = false;

  private showCreatorsSearch = false;

  private askWontDoReason = false;

  private statusChangeInProgress = false;

  enabledFieldGroupIds: Array<string> = [];

  get ticket(): Ticket {
    const ticketId = this.$route.params.id;
    return this.ticketsByCanonicalId.get(ticketId) || new Ticket();
  }

  get baseTasks(): any {
    return this.$refs.baseTasks as any;
  }

  get timeLineStatuses(): Array<string> {
    return this.activeWorkflowStatuses
      && this.activeWorkflowStatuses.filter((s) => s !== ImproveTicketStatus.WONT_DO);
  }

  get activeStatuses(): Array<string> {
    if (this.ticket?.status === ImproveTicketStatus.WONT_DO) {
      return [this.lastValidStatus || '', ImproveTicketStatus.WONT_DO];
    }
    if (this.ticket?.status === ImproveTicketStatus.ARCHIVED) {
      return [this.lastValidStatus || '', ImproveTicketStatus.ARCHIVED];
    }
    return this.timeLineStatuses;
  }

  get lastValidStatus(): string | undefined {
    if (!this.ticket) {
      return '';
    }
    return this.ticket.getLastValidStatus(
      [ImproveTicketStatus.WONT_DO, ImproveTicketStatus.ARCHIVED]
    );
  }

  get wontDoTicket(): boolean {
    return this.ticket && this.ticket.status === ImproveTicketStatus.WONT_DO;
  }

  get archivedTicket(): boolean {
    return this.ticket && this.ticket.status === ImproveTicketStatus.ARCHIVED;
  }

  get ticketDelegatedTeam(): Team | null {
    return this.ticket?.delegateIsTeam()
      ? this.allTeamsById(this.ticket.getDelegateID()!)
      : null;
  }

  get ticketDelegatedTeamStats(): StatsData | undefined {
    const team = this.ticket?.delegateIsTeam()
      ? this.allTeamsById(this.ticket.getDelegateID()!)
      : null;
    return team && this.teamStatsByID.has(team.id!)
      ? this.teamStatsByID.get(team.id!)
      : new StatsData();
  }

  get ticketCreatedAt(): string {
    if (!this.ticket?.createdDate) {
      return '';
    }
    return this.$date(this.ticket.createdDate).format('DD. MMM YYYY');
  }

  get ticketDeadline(): string {
    if (!this.ticket?.deadLine) {
      return '';
    }
    return this.$date(this.ticket.deadLine).format('DD. MMM YYYY');
  }

  get actualDate(): string {
    if (!this.ticket) {
      return '';
    }
    return this.$date(this.ticket.meta.implementationDate, 'YYYY-MM-DD').format('DD. MMM YYYY');
  }

  get wontDoReason(): string {
    return this.ticket?.meta?.wontDoReason || '';
  }

  get ticketAssignmentOptions(): MenuOption[] {
    const options: Array<MenuOption> = [];

    if (this.ticket?.assignee !== this.currentUser?.id) {
      options.push({
        title: this.$t('menu.assignToMe').toString(),
        value: 'assignToMe'
      }, {
        title: this.$t('menu.assignToSomeone').toString(),
        value: 'assignToSomeone'
      });
    }

    if (this.ticket?.assignee === this.currentUser?.id) {
      options.push({
        title: this.$t('menu.assignToSomeone').toString(),
        value: 'assignToSomeone'
      });
    }

    return options;
  }

  get ticketCreatorOptions(): MenuOption[] {
    const options: Array<MenuOption> = [];

    if (this.ticket?.creator !== this.currentUser?.id) {
      options.push({
        title: this.$t('menu.changeToMe').toString(),
        value: 'changeToMe'
      }, {
        title: this.$t('menu.changeCreator').toString(),
        value: 'changeCreator'
      });
    }

    if (this.ticket?.creator === this.currentUser?.id) {
      options.push({
        title: this.$t('menu.changeCreator').toString(),
        value: 'changeCreator'
      });
    }

    return options;
  }

  async created(): Promise<void> {
    await this.fetchTicket();
    await this.fetchWorkflow(this.ticket.type);
    await this.fetchDelegatedTeamStats();
    await this.initExternalFields();
  }

  isImage(mimeType: string): boolean {
    return !!mimeType?.includes('image');
  }

  isVideo(mimeType: string): boolean {
    return !!mimeType?.includes('video');
  }

  getFileExtension(file: FileModel): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(file.filename)![1]?.toUpperCase() || '';
  }

  textFileName(file: FileModel): string {
    return file.filename.length < 16
      ? file.filename
      : file.filename.substr(0, 13).concat('...');
  }

  async fetchTicket(): Promise<Ticket> {
    const ticketId = this.$route.params.id;
    return this.getTicketById(ticketId).catch(() => this.$router.push({ name: 'Dashboard' }));
  }

  async fetchDelegatedTeamStats(): Promise<void> {
    const teamId = this.ticket?.delegateIsTeam() ? this.ticket?.getDelegateID() : null;
    if (teamId) {
      await this.fetchTeamStats([teamId]);
    }
  }

  async initExternalFields(): Promise<void> {
    await this.fetchExternalFields(this.ticket.type);
    const enabledIds: Array<string> = [];
    const meta = this.ticket.meta as any;
    this.externalFields.forEach((fieldGroup) => {
      fieldGroup.fields.every((field) => {
        if (meta[field.ref]) {
          enabledIds.push(fieldGroup.groupId);
          return false; // Don't need to check further
        }
        return true;
      });
    });
    this.enabledFieldGroupIds = enabledIds;
  }

  onMenuOptionClick(option: MenuOption): void {
    if (!this.ticket) return;
    if (option.value === 'assignToMe') {
      this.ticket.assignee = this.currentUser?.id;
      this.updateTicket(this.ticket);
    }
    if (option.value === 'assignToSomeone') {
      this.showUsersSearch = true;
    }
    if (option.value === 'changeToMe') {
      this.ticket.creator = this.currentUser?.id;
      this.updateTicket(this.ticket);
    }
    if (option.value === 'changeCreator') {
      this.showCreatorsSearch = true;
    }
  }

  goToEditImprove(): void {
    this.$router.push({
      name: 'EditTicket',
      params: { id: this.ticket.canonicalId! }
    });
  }

  async setStatusToWontDoConfirmation(reason: string): Promise<void> {
    if (!this.ticket) return;
    this.statusChangeInProgress = true;
    this.ticket.meta.wontDoReason = reason;
    await this.updateTicket(this.ticket);
    await this.changeTicketStatus(ImproveTicketStatus.WONT_DO);
    this.statusChangeInProgress = false;
    this.askWontDoReason = false;
  }

  async reactiveTaskUpdates(params: TaskUpdateParams): Promise<TaskUpdateParams> {
    await this.updateTasks({
      parent: this.ticket,
      updates: params
    });
    return Promise.resolve(params);
  }

  async changeTicketStatus(newStatus: string | undefined): Promise<void> {
    if (!this.ticket || this.ticket.status === newStatus) {
      return;
    }
    try {
      await this.updateTicketStatus({
        ticket: this.ticket,
        fromStatus: this.ticket.status,
        toStatus: newStatus
      });
      this.setAlertMessage({
        message: `${this.$t('page.userProfile.assignmentsTab.improveMovedTo')}
        ${this.$t('ticket.status.'.concat(newStatus!))}
        ${this.$t('page.userProfile.assignmentsTab.phase')}`,
        show: true
      });
    } catch (error) {
      this.setAlertMessage(error);
    }

    this.fetchTicket();
  }

  closeSearch(): void {
    this.showUsersSearch = false;
  }

  showSearch(): void {
    this.showUsersSearch = true;
  }

  closeCreatorSearch(): void {
    this.showCreatorsSearch = false;
  }

  showCreatorSearch(): void {
    this.showCreatorsSearch = true;
  }

  updateAssignee(user: User): void {
    this.ticket.assignee = user.id;
    this.updateTicket(this.ticket);
    this.closeSearch();
  }

  updateCreator(user: User): void {
    this.ticket.creator = user.id;
    this.updateTicket(this.ticket);
    this.closeCreatorSearch();
  }

  downloadFile(file: FileModel): void {
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${file.filename.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
