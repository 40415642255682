





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '../widgets/BaseButton.vue';

@Component({
  name: 'TicketStatusSwitcher',
  components: {
    BaseButton
  }
})
export default class TicketStatusSwitcher extends Vue {
  @Prop({ default: () => [] }) readonly statuses!: Array<string>;

  @Prop({ default: null }) readonly currentStatus!: string;

  @Prop({ default: false }) readonly loading!: boolean;

  get isFirstStatus(): boolean {
    return this.currentStatusIndex === 0;
  }

  get isLastStatus(): boolean {
    return this.statuses
      && this.currentStatusIndex === this.statuses.length - 1;
  }

  get currentTranslatedStatus(): string | null {
    return this.statuses ? this.statuses[this.currentStatusIndex] : null;
  }

  get currentStatusIndex(): number {
    return this.statuses ? this.statuses?.indexOf(this.currentStatus!) : 0;
  }

  changeStatusToPreviousState(): void {
    this.$emit('change', this.getNewStatus(this.currentStatusIndex - 1));
  }

  changeStatusToNextState(): void {
    this.$emit('change', this.getNewStatus(this.currentStatusIndex + 1));
  }

  getNewStatus(newIndex: number): string {
    const newStatusIndex = Math.max(Math.min(newIndex, this.statuses.length - 1), 0);
    return this.statuses[newStatusIndex];
  }
}
