





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import BaseUserAvatar from './BaseUserAvatar.vue';
import BaseMenu from './BaseMenu.vue';

@Component({
  name: 'BaseUserThumbnail',
  components: {
    BaseUserAvatar,
    BaseMenu
  }
})
export default class BaseUserThumbnail extends Vue {
  @Getter userById!: (uId: string) => User | null;

  @Getter allTeamsById!: (tId: string) => Team | null;

  @Prop({ default: 46 }) readonly imageSize!: number;

  @Prop({ default: null }) readonly userId!: string;

  get user(): User | null {
    return this.userById(this.userId);
  }

  get userAvatar(): string {
    if (!this.user) return '';
    return `${this.user.avatar}`;
  }

  get userName(): string {
    if (!this.user) return '';
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get unitName(): string {
    const team = this.user && this.user.unit
      ? this.allTeamsById(this.user.unit)
      : null;
    return team ? team.name : '';
  }
}
