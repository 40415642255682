






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Team from '@improve/common-utils/src/model/Team';
import Topic from '@improve/common-utils/src/model/Topic';
import BaseUserAvatar from './BaseUserAvatar.vue';

@Component({
  name: 'BaseDelegateThumbnail',
  components: {
    BaseUserAvatar
  }
})
export default class BaseDelegateThumbnail extends Vue {
  @Getter allTeamsById!: (tId: string) => Team | null;

  @Getter organizationTopicsById!: (tId: string) => Topic | null;

  @Prop({ default: null }) readonly delegateId!: string;

  @Prop({ default: false }) readonly delegateIsTeam!: boolean;

  @Prop({ default: false }) readonly delegateIsTopic!: boolean;

  goToTopicOverview(): void {
    if (this.delegateId) {
      this.$router.push({
        name: 'TopicImproves',
        params: { id: this.delegateId }
      });
    }
  }

  get delegateShortName(): string {
    return this.delegateName.toString()[0];
  }

  get delegateName(): string {
    if (this.delegateIsTopic) {
      const topic = this.organizationTopicsById(this.delegateId);
      return (topic && topic.metaData.displayName) || '';
    }
    if (this.delegateIsTeam) {
      const team = this.allTeamsById(this.delegateId);
      return (team && team.name) || '';
    }
    return '';
  }

  get delegateType(): string {
    if (this.delegateIsTopic) {
      return 'common.topic';
    }
    if (this.delegateIsTeam) {
      return 'common.team';
    }
    return '';
  }

  get delegateColor(): string | null {
    if (this.delegateIsTopic) {
      const topic = this.organizationTopicsById(this.delegateId);
      return topic && topic.metaData.color;
    }
    return null;
  }
}
