




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TimeLineStatuses'
})
export default class TimeLineStatuses extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean;

  @Prop({ default: null }) readonly lastValidStatus!: string;

  @Prop({ default: () => [] }) readonly statuses!: Array<string>;

  get currentStatusIndex(): number {
    return this.statuses ? this.statuses?.indexOf(this.lastValidStatus as string) : 0;
  }
}
