










import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  name: 'BaseSkeleton'
})
export default class BaseSkeleton extends Vue {}
