

































import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';

import Ticket from '@improve/common-utils/src/model/Ticket';
import ReactionBoard from '@improve/common-utils/src/model/ReactionBoard';
import Reaction from '@improve/common-utils/src/model/Reaction';
import User from '@improve/common-utils/src/model/User';
import Comment from '@improve/common-utils/src/model/Comment';
import { Emoji } from '../../types/EmojiTypes';
import BaseTicketComments from './BaseTicketComments.vue';
import EmojiViewer from './EmojiViewer.vue';
import BaseTicket from '../../helpers/BaseTicket.vue';

@Component({
  name: 'TicketDetailReactions',
  components: {
    BaseTicketComments,
    EmojiViewer
  }
})
export default class TicketDetailReactions extends BaseTicket {
  @Prop({ required: true }) readonly ticket!: Ticket;

  @Getter currentUser?: User;

  @Getter reactionBoardsById!: Map<string, ReactionBoard>;

  @Action createTicketComment!: ActionMethod;

  private commentPostInProgress = false;

  get reactionBoard(): ReactionBoard | null {
    if (!this.ticket) return null;
    return (this.reactionBoardsById
      && this.reactionBoardsById.get(this.ticket.canonicalId!))
      || null;
  }

  get reactions(): Array<Reaction> {
    if (!this.ticket) return [];
    const board = this.reactionBoard;
    return board ? this.getReactions(this.ticket, board) : [];
  }

  get comments(): Array<Comment> {
    if (!this.ticket) return [];
    const board = this.reactionBoard;
    return (board && board.comments) || [];
  }

  addEmoji(emoji: Emoji): void {
    if (!this.ticket) return;
    this.addNewEmoji(emoji, this.ticket);
  }

  reactOrUnReactEmoji(reaction: Reaction): void {
    if (!this.ticket || !this.currentUser) return;
    this.reactOrUnReact(reaction, this.ticket, this.currentUser);
  }

  async postNewComment(comment: string): Promise<void> {
    if (!this.ticket) return;
    // Need to implement the "loading" store
    this.commentPostInProgress = true;
    await this.createTicketComment({
      ticketId: this.ticket.canonicalId,
      comment
    });
    this.commentPostInProgress = false;
  }
}
